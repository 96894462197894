.change_password_field {
  width: 50%;
  margin: auto;
}

.change_password_title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .change_password_field {
    width: 90%;
  }
}
