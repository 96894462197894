.dashboard {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30px;
  gap: 30px;
}

.dashboard_element {
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.merchants_field {
  border: 2px solid #339cfd;
  background-color: #339cfd69;
}
.users_field {
  border: 2px solid #57d682;
  background-color: #57d68269;
}
.trades_field {
  border: 2px solid #ffb765;
  background-color: #ffb76569;
}

.dashboard_element_title {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: repeat(1, 1fr);
    padding: 16px;
  }
}
