.chat_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  gap: 4px;
}

.chat_information {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
  background-color: #1a1e23;
  padding: 16px;
}

.chat_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.right_side {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.back_icon {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

.user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.user_rating {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star_icon {
  width: 16px;
  height: 16px;
}

.rating {
  font-size: 20px;
  color: #fff;
}

.user_name_section {
}
.user_name {
  color: #fff;
  font-size: 20px;
}

.left_side {
  display: flex;
  align-items: center;
  gap: 12px;
}

.trade_info {
}
.trade_id {
  color: #b2bcce;
  font-size: 16px;
  line-height: 24px;
}

.chat_line {
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  background-color: #2b2c3b;
}

.about_trade {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.about_item {
  display: flex;
  flex-direction: column;
}

.item_title {
  font-size: 14px;
  color: #b2bcce;
  line-height: 22px;
}

.item_value {
  font-size: 16px;
  color: #b2bcce;
  line-height: 24px;
}

.value_with_image {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.about_trade_icon {
  width: 24px;
  height: 24px;
}

.chat_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #14181d;
}

.chat_actions_field {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #484956;
  background-color: #1b1e43;
  width: 100%;
  box-sizing: border-box;
}

.chat_input_field {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}

.chat_send_upload {
  display: flex;
  flex-direction: row;
  font-size: 0;
  gap: 6px;
  align-self: end;
}

.chat_send_upload_button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.action_icon {
  width: 40px;
  height: 40px;
}

.chat_input {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  outline: none;
  resize: none;
  box-sizing: border-box;
  width: 80%;
}

.chat_input::-webkit-scrollbar {
  width: 4px;
  background-color: #202429;
}

.chat_input::-webkit-scrollbar-track {
  -webkit-box-shadow: #202429;
}

.chat_input::-webkit-scrollbar-thumb {
  background-color: #484956;
  border-radius: 2px;
}

.upload_file_drag {
  display: none;
}

.chat_actions {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0;
  border-top: 1px solid #2b2c3b;
}

.arbitrage_field {
  display: flex;
  align-items: center;
  gap: 6px;
}

.arbitrage_icon {
  width: 24px;
  height: 24px;
}

.arbitrage {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.chat_action_buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.images_grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: scroll;
  gap: 6px;
  padding: 10px 16px 10px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.images_grid::-webkit-scrollbar {
  display: none;
}

.grid_element {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  position: relative;
}

.images_grid:hover .remove_icon {
  display: block;
  transition: all 0.3s;
}

.image_grid_field {
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.grid_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.remove_icon {
  position: absolute;
  top: -7px;
  right: -6px;
  width: 18px;
  height: 18px;
  display: none;
  transition: all 0.3s;
}

.close_icon {
  width: 18px;
  height: 18px;
}

.chat_messages_section {
  width: 100%;
  box-sizing: border-box;
  background-color: #35385c;
  padding-top: 24px;
  height: 300px;
}

.chat_messages {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #35385c;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 24px 24px;
  overflow-y: scroll;
}

.chat_empty_messages {
  align-items: center;
  justify-content: center;
}

.chat_messages::-webkit-scrollbar {
  width: 4px;
  background-color: #303359;
}

.chat_messages::-webkit-scrollbar-track {
  -webkit-box-shadow: #303359;
}

.chat_messages::-webkit-scrollbar-thumb {
  background-color: #484956;
  border-radius: 2px;
}

.chat_date_field {
  display: flex;
  justify-content: center;
  padding: 6px;
}

.chat_date {
  padding: 2px 16px;
  border-radius: 100px;
  background-color: #2b2c3b;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
}

.message_field {
  width: 73%;
  box-sizing: border-box;
  padding: 6px 12px;
  background-color: #202429;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-self: flex-start;
  border-radius: 8px;
}

@media (max-width: 480px) {
  .message_field {
    width: 85%;
  }
}

.sender_name {
  color: #fff;
  line-height: 22px;
  font-size: 14px;
}

.message {
  color: #b2bcce;
  font-size: 14px;
  line-height: 22px;
}

.message_date {
  display: flex;
  align-items: center;
  gap: 2px;
  align-self: flex-end;
}

.date {
  color: #b2bcce;
  font-size: 12px;
  line-height: 18px;
}

.chat_image {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

@media (max-width: 768px) {
  .chat_image {
    grid-template-columns: repeat(2, 1fr);
  }
}

.images_grid_element {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.attach_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.last_element {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #2b2c3bcc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 24px;
  font-size: 16px;
}

.one_images_grid_element {
  width: 100%;
  height: auto;
}

.one_images_grid_element > .attach_image {
  width: 260px;
  max-width: 100%;
  height: auto;
}

.my_messages {
  align-self: flex-end;
  background-color: #060b11;
}

.admin_message {
  background-color: #2b2c3b;
}

.passport_modal {
  background-color: #1a1e23;
  padding: 8px 8px 17px;
  max-width: 650px;
  gap: 0;
}

.images_field {
  padding: 43px 69px 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.swiper_image_element {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

@media (max-width: 480px) {
  .swiper_image_element {
    width: 100%;
    height: 100%;
  }
}

.arrow_container {
  display: flex;
  justify-content: center;
  gap: 16px;
  cursor: pointer;
}
